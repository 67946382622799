import './App.css';
import {BrowserRouter} from "react-router-dom";

import React, {useContext, useEffect, useState, Suspense} from "react";
import {Context} from "./index";
import Loader from "./UI/Loader/Loader";
import {check, fetchUserParkAccess} from "./http/userAPI";
import {observer} from "mobx-react-lite";


const AppRouter = React.lazy(() => import('./components/appRouter'));
const Footer = React.lazy(() => import('./components/footer/Footer'));
const MyNavbar = React.lazy(() => import('./components/navbar/MyNavbar'));


const App = observer(() => {
    const {user} = useContext(Context)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        check().then(data => {
            if(typeof data === 'object') {
                user.setUser(data);
                user.setIsAuth(true);
            }
        }).then(() =>
            fetchUserParkAccess().then(data => {
                if(data?.parks?.length>0){
                    user.setParksAccess(data.parks)
                }
                if(data?.parkQueues?.length>0){
                    user.setParkQueuesAccess(data.parkQueues)
                }
            })

        ).finally(() => {
            setLoading(false)
        })
    },[])
    if(loading) {
        return <Loader />
    }
    return (
      <BrowserRouter>
          <Suspense>
              <MyNavbar/>
          </Suspense>
          <div className='content-container'>
              <Suspense fallback={<Loader />}>
                  <AppRouter/>
              </Suspense>
          </div>

        <Suspense>
            <Footer/>
        </Suspense>
      </BrowserRouter>
  );
})

export default App;
