import {makeAutoObservable} from "mobx";

export default class UserStore {
    constructor() {
        this._isAuth = false
        this._user = {}
        this._parksAccess = []
        this._parkQueuesAccess = []
        makeAutoObservable(this)
    }

    setIsAuth(bool) {
        this._isAuth = bool
    }

    setUser(user) {
        this._user = user
    }

    setParksAccess(parksAccess){
        this._parksAccess = parksAccess
    }

    setParkQueuesAccess(parkQueuesAccess){
        this._parkQueuesAccess = parkQueuesAccess
    }

    get isAuth() {
        return this._isAuth
    }


    get user() {
        return this._user
    }

    get parksAccess() {
        return this._parksAccess
    }

    get parkQueuesAccess() {
        return this._parkQueuesAccess
    }


}